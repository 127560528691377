import React from 'react';
import { Button, Col, Row, List } from 'antd';

import { styles } from '../layout/page';

const FoodForEveryone = () => (
  <div className={styles.hero}>
    <div className={styles.wrapper}>
      <Row gutter={32} type='flex' justify='center'>
        <Col xs={24} sm={24} md={24} lg={18} xl={18}>
          <section className={styles.content}>
            <h1 className={styles.title}>
              <img src='/heart-outline.svg' className={styles.icon} />
              <span>Food for Everyone</span>.
              <sup className={styles.trademark}>™</sup>
            </h1>
            <div className={styles.body}>
              <p>
                <span className={styles.subtitle}>
                  1 in 8 Americans struggles with food insecurity everyday.
                </span>{' '}
                In a world where technology often creates social distance, those
                less fortunate are getting left behind. We have an abundance of
                food, yet people still go hungry.
              </p>
              <p>
                <span className={styles.subtitle}>
                  We're working to change that.
                </span>{' '}
                We believe the same technology that divides us can foster human
                connection and facilitate more effective solutions. We're
                forging a sustainable, mutually beneficial ecosystem for food
                and drink establishments and their customers to help end local
                hunger. Working together with local partners and food community
                experts, we're creating new and better ways to share food and
                experiences with others.
              </p>
            </div>
            <Button
              className={styles.action}
              href='/contact'
              type='primary'
              size='large'>
              <span>Help the Hungry</span>
            </Button>
          </section>
        </Col>
      </Row>
    </div>
  </div>
);

export default FoodForEveryone;
