import React from 'react';

import SEO from '../components/content/seo';
import Layout from '../containers/layout';
import Page from '../components/layout/page';
import FoodForEveryone from '../components/layout/food-for-everyone';

const seo = {
  title: 'Food for Everyone',
  description: 'Food for Everyone page.',
  keywords: ['food', 'social', 'impact'],
};

const FoodForEveryonePage = () => {
  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
      <Page>
        <FoodForEveryone />
      </Page>
    </Layout>
  );
};

export default FoodForEveryonePage;
